
import Vue from 'vue';

export default Vue.extend({
  name: 'PageHeading',
  props: {
    msg: String,
  },
  methods: {
    goHome: function () {
      window.location.assign('/');
    }
  }
});
