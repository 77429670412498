import Vue from 'vue';
import axios, { AxiosStatic } from "axios";
import {ToastUtils} from "@/common";
import {ServerResponse} from "@/model/restResponse";

console.log("mode: " + process.env.NODE_ENV);
const baseURL = '/vault'
console.log("BaseURL: " + baseURL);

// if (typeof baseURL !== 'undefined') {
//     axios.defaults.baseURL = baseURL;
// }

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
    baseURL: baseURL
    // timeout: 60 * 1000, // Timeout
    // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function(config) {
        // Do something before request is sent
        return config;
    },
    function(error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function(response) {
        // Do something with response data
        return response;
    },
    function(error) {

      const serverResponse: ServerResponse = error.response.data;
      if (error.response.status == 400) {
        ToastUtils.showError("400 Bad Request",
          serverResponse.message)
      }
      if (error.response.status == 404) {
        ToastUtils.showError("404 Not Found",
          serverResponse.message)
      }

      if (error.response.status >= 500) {
        ToastUtils.showError("Status code: " + error.response.status,
          "Something went wrong! " + serverResponse.message)
      }
        // Do something with response error
        return Promise.reject(error);
    }
);

Vue.prototype.$axios = _axios;
declare module 'vue/types/vue' {
    interface Vue {
        $axios: AxiosStatic;
    }
}
