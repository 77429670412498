export class AuthorizationRequest {

  constructor(responseType: string, clientId: string, redirectURI: string) {
    this.responseType = responseType;
    this.clientId = clientId;
    this.redirectURI = redirectURI;
  }

  responseType: string

  clientId: string

  redirectURI: string

  codeChallenge: string

  codeChallengeMethod: string
}
