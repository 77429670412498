import {decl} from "postcss";

export class AuthCodeRequest {
  authorizationCode: string
  clientId: string
  // clientSecret: string
  codeVerifier: string
}

export interface OAuthToken {
  idToken: string
  accessToken: string
  scope: string
  expired: boolean
  created: Date
  updated: Date
  revoked: boolean,
  user: User
}

export interface User {
  username: string,
  email: string
}

export interface LoginDevice {
  id: number;
  userAgentFamily: string;
  userAgentVersion: string;
  osFamily: string;
  osVersion: string;
  deviceFamily: string;
}

export interface LoginHistory {
  id: number;
  ipOrFqdn: string;
  loginResult: string;
  loginDevice: LoginDevice;
  timestamp: Date;
}

export interface LoginHistoryModel {
  id: number,
  originAddress: string,
  loginResult: string,
  userAgent: string,
  deviceOs: string,
  device: string,
  time: Date,
  timeFormatted: string
}
