import Vue from "vue";

export class ToastUtils {

  public static showSuccess(title: string,
                         message: string) {
    ToastUtils.showToast(title, message, "success", true)
  }


  public static showInfo(title: string,
                         message: string) {
    ToastUtils.showToast(title, message, "primary", true)
  }

  public static showError(title: string,
                          message: string) {
    ToastUtils.showToast(title, message, "danger", true)
  }

  public static showToast(title: string,
                          message: string,
                          variant?: string,
                          solid?: boolean) {
    new Toast().makeToast(title, message, variant, solid)
  }
}

class Toast extends Vue.extend({
  name: 'ErrorHandler.vue',
  template: `<template>
    <div></div>
  </template>`,
  props: {
    variant: {
      type: String,
      required: false
    },
    solid: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    makeToast(title: string, message: string, variant?: string, solid?: boolean) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: solid == undefined ? true : solid
      })
    }
  }
}){}
