import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/vault',
    name: 'Vault',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "vault" */ '@/views/Vault.vue'),
    redirect: '/vault/search',
    children: [
      {
        path: 'search',
        name: 'Search',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/Search.vue'),
        props: true
      },
      {
        path: 'add',
        name: 'AddSensitiveInfo',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/AddSensitiveInfo.vue')
      },
      {
        path: 'list',
        name: 'ListSensitiveInfo',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/ListSensitiveInfo.vue')
      },
      {
        path: 'shared',
        name: 'ListSharedSensitiveInfo',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/ListSharedSensitiveInfo.vue')
      },
      {
        path: 'settings',
        name: 'Settings',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/VaultSettings.vue')
      },
      {
        path: '*',
        name: '404',
        component: () => import('@/views/404.vue')
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
