
import Vue from 'vue';
import {AuthorizationRequest} from "@/model/authorization";
import {Authorizer} from "@/service/authorizer";
import {RestResponse} from "@/model/restResponse";
import router from "@/router";

export default Vue.extend({
  name: "Login",
  data: function() {
    return {
      username: '',
      password: '',
      formErrors : {
        username: '',
        password: '',
        authCode: '',
        loginError: ''
      },
      errorHighlight: '',
      authorizer : new Authorizer(),
      authCode: '',
      showAuthCodeInput: false,
      busy: false
    }
  },
  methods: {

    resetErrors: function () {
      const vm = this;
      vm.formErrors.username = ''
      vm.formErrors.password = ''
      vm.formErrors.loginError = ''
      vm.errorHighlight = ''
    },

    reset: function () {
      console.log("Resetting...")
      const vm = this;
      vm.username = ''
      vm.password = ''
      vm.resetErrors()
    },

    validate: function () {
      const vm = this;
      if (!vm.username) {
        vm.formErrors.username = 'Please enter your username';
      }
      if (!vm.password) {
        vm.formErrors.password = 'Please enter your password';
      }
    },

    login: async function () {
      if (this.showAuthCodeInput) {
        try {
          this.busy = true
          await this.requestToken()
        } finally {
          this.busy = false
        }
        return
      }
      const vm = this;
      vm.resetErrors()
      vm.validate()
      if (vm.formErrors.username || vm.formErrors.password) {
        vm.errorHighlight = 'placeholder-red-500';
        return
      }
      console.log("Logging in")
      try {
        this.busy = true
        const authReq = new AuthorizationRequest('TOKEN', 'web_app', '/');
        const initAuthzResponse: RestResponse<string> = await vm.authorizer.initAuthorization(authReq);
        if (!initAuthzResponse.success) {
          alert("Failed: " + initAuthzResponse.failureMessage);
          return
        }
        const contentLocation = initAuthzResponse.response;
        console.log("Content-Location: " + contentLocation)

        const loginResponse: RestResponse<boolean> = await vm.authorizer.login(vm.username, vm.password, contentLocation);
        if (!loginResponse.success) {
          // alert(loginResponse.failureMessage)
          vm.formErrors.loginError = loginResponse.failureMessage
          return
        }
        this.showAuthCodeInput = true
      } finally {
        this.busy = false
      }
    },

    requestToken: async function() {
      const vm = this;
      if (!this.authCode) {
        this.formErrors.authCode = "Auth code is required"
        return
      }
      const restResponse: RestResponse<boolean> = await vm.authorizer.requestToken(this.authCode)
      if (!restResponse.success) {
        vm.formErrors.loginError = restResponse.failureMessage
        return
      }
      // alert("Success")
      await router.replace({
        name: "Vault",
        replace: true
      });
    }
  }
})
