export class DataHolder {

  private static obj = new Map<string, string>()

  public static holdData(key: string, value: string): void {
    this.obj.set(key, value)
  }

  public static retrieveData(key: string): string | undefined {
    return this.obj.get(key)
  }
}
