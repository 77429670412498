import CryptoJS from "crypto-js/core"
import sha256 from 'crypto-js/sha256';
import Base64 from "crypto-js/enc-base64"

export class CryptUtils {

  public static generateCodeVerifier(): string {
    return CryptUtils.generateRandomString(128)
  }
  public static generateRandomString(length: number): string {
    let text = ""
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~"

    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    }
    return text;
  }

  public static generateCodeChallenge(code_verifier: string): string {
    const sha256Val = sha256(code_verifier)
    return CryptUtils.base64URL(sha256Val)
  }

  public static base64URL(sha256Val: CryptoJS.lib.WordArray): string {
    return sha256Val.toString(Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
  }
}
