<template>
  <b-container id="app" fluid>
    <Page-Heading msg="Vault" class="mb-1"/>
    <Login />
  </b-container>
</template>

<script>
// @ is an alias to /src
import Vue from 'vue';
import PageHeading from '@/components/Page-Heading.vue';
import Login from '@/components/Login.vue';
import "@/assets/tailwind.css";

export default Vue.extend({
  name: 'LoginVue',
  components: {
    PageHeading,
    Login
  }
});
</script>
