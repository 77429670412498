import {AuthorizationRequest} from "@/model/authorization"
import {RestResponse, ServerResponse} from "@/model/restResponse"
import Vue from "vue"
import {AuthCodeRequest, OAuthToken, User} from "@/model/types"
import router from "@/router"
import {CryptUtils} from "@/service/crypt"
import {DataHolder} from "@/service/dataHolder";
import {OAuthEnhancedDropzoneOptions, OAuthEnhancer} from "@/service/util";
import {DropzoneOptions} from "dropzone";
import {ToastUtils} from "@/common";

export class Authorizer extends Vue {

  codeVerifier: string
  codeChallenge: string

  private static loggedOut = false

  async initAuthorization(authorizationRequest: AuthorizationRequest) : Promise<RestResponse<string>> {

    this.codeVerifier = CryptUtils.generateCodeVerifier()
    this.codeChallenge = CryptUtils.generateCodeChallenge(this.codeVerifier)
    const url = `/api/oauth/authorize`
    authorizationRequest.codeChallenge = this.codeChallenge
    authorizationRequest.codeChallengeMethod = "S256"

    return new Promise<RestResponse<string>>(resolve => {
      this.$axios.post(url, authorizationRequest)
        .then(function (response) {
          const contentLocation: string = response.headers["content-location"]
          if (!contentLocation) {
            const restResponse = new RestResponse<string>(false,
              '',
              "ContentLocation missing",
              '',
              response.headers,
              response.status)
            resolve(restResponse)
            return
          }

          const restResponse = new RestResponse<string>(true,
            'Success',
            '',
            contentLocation,
            response.headers,
            response.status)
          resolve(restResponse)
        })
        .catch(function (error) {
          console.log(error)
          const restResponse = new RestResponse<string>(false,
            '',
            "Something went wrong!!",
            '',
            null)
          resolve(restResponse)
        })
    })
  }

  async login(username: string, password: string, url: string): Promise<RestResponse<boolean>> {
    const authHeaderValue = btoa(`${username}:${password}`)
    return new Promise<RestResponse<boolean>>(resolve => {
      this.$axios.post(url, {},{
        headers: {
          'Authorization': `Basic ${authHeaderValue}`
        }
      }).then(function (response) {
        if (response.status == 200) {
          const restResponse = new RestResponse<boolean>(true,
            'Successfully logged in',
            '',
            true,
            response.headers,
            response.status)
          resolve(restResponse)
        } else {
          console.log("error: " + response.status)
          const restResponse = new RestResponse<boolean>(false,
            '',
            response.data,
            false,
            response.headers,
            response.status)
          resolve(restResponse)
        }
      }).catch(function (error) {
        console.log(error)
        const failureMessage = error?.response?.data?.message ? error.response.data.message : error.message
        const restResponse = new RestResponse<boolean>(false,
          '',
          failureMessage,
          false,
          null)
        resolve(restResponse)
      })
    })
  }

  public async requestToken(authorizationCode: string): Promise<RestResponse<boolean>> {
    const authCodeRequest: AuthCodeRequest = new AuthCodeRequest()
    authCodeRequest.authorizationCode = authorizationCode
    authCodeRequest.clientId = "web_app"
    if (!this.codeVerifier) {
      alert("Cannot continue, please refresh and start over")
      return new Promise<RestResponse<boolean>>(resolve => {
        const restResponse = new RestResponse<boolean>(false,
          '',
          'code-verifier is undefined',
          false,
          null)
        resolve(restResponse)
      })
    }
    authCodeRequest.codeVerifier = this.codeVerifier
    const url = "/api/oauth/token"
    const vm = this
    return new Promise<RestResponse<boolean>>(resolve => {
      this.$axios.post(url, authCodeRequest,{})
        .then(function (response) {
        if (response.status == 200) {
          const oAuthToken: OAuthToken = response.data
          vm.decorateWithOAuthHeader(oAuthToken)
          vm.enhanceVueWithOAuthToken(oAuthToken)
          vm.setUsernameInStorage(oAuthToken.user.username)
          const restResponse = new RestResponse<boolean>(true,
            'Token received',
            '',
            true,
            response.headers,
            response.status)
          resolve(restResponse)
        } else {
          console.log("error: " + response.status)
          const restResponse = new RestResponse<boolean>(false,
            '',
            response.data,
            false,
            response.headers,
            response.status)
          resolve(restResponse)
        }
      }).catch(function (error) {
        console.log(error)
        const failureMessage = error?.response?.data?.message ? error.response.data.message : error.message
        const restResponse = new RestResponse<boolean>(false,
          '',
          failureMessage,
          false,
          null)
        resolve(restResponse)
      })
    })
  }

  private setUsernameInStorage(username: string): void {
    try {
      DataHolder.holdData("username", username)
    } catch (err) {
      console.error("Failed to set username: " + err)
    }
  }

  private decorateWithOAuthHeader(oAuthToken: OAuthToken) {
    console.log("decorateWithOAuthHeader...")
    Authorizer.loggedOut = false
    this.$axios.interceptors.request.use((config) => {
      if (Authorizer.loggedOut) {
        oAuthToken.idToken = ''
        oAuthToken.accessToken = ''
        oAuthToken.scope = ''
        oAuthToken.created = {} as Date
        oAuthToken.updated = {} as Date
        oAuthToken.revoked = {} as boolean
        oAuthToken.user = {} as User
        return config
      }

      if(!config || !config.headers) {
        return config
      }

      if (!config.headers.Authorization && // If auth header does not exist.
          oAuthToken && oAuthToken.accessToken) {
        // console.log("attaching bearer...")
        const token = btoa(oAuthToken.accessToken)
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    })
    this.$axios.interceptors.response.use(undefined, (error) => {
      console.log("authorizer.error..." + error)
      if (401 === error.response.status) {
        console.log("UnAuthorized status received, redirecting to home...")
        ToastUtils.showError("UnAuthorized", "Token expired")
        oAuthToken = {} as OAuthToken
        router.replace({
          name: "Login",
          replace: true
        })
      }
      return Promise.reject(error)
    })
    Vue.prototype.$axios = this.$axios
  }

  private enhanceVueWithOAuthToken(oAuthToken: OAuthToken) {
    const oAuthEnhancer: OAuthEnhancer<DropzoneOptions, DropzoneOptions>
      = new OAuthEnhancedDropzoneOptions(oAuthToken.accessToken)
    Vue.prototype.oAuthEnhancer = oAuthEnhancer
  }

  public async logout(): Promise<ServerResponse> {
    const url = "/api/logout"
    return new Promise<ServerResponse>(resolve => {
      this.$axios.post(url)
        .then(function (response) {
          if (response.status >= 200 && response.status < 300) {
            const serverResponse: ServerResponse = response.data
            Authorizer.loggedOut = true
            resolve(serverResponse)
          } else {
            console.log("error: " + response.status)
            const serverResponse: ServerResponse = {
              message: "Failed",
              status: "Failed",
              reason: "Failed"
            }
            resolve(serverResponse)
          }
        }).catch(function (error) {
        console.log(error)
        const failureMessage = error?.response?.data?.message ? error.response.data.message : error.message
        const serverResponse: ServerResponse = {
          message: failureMessage,
          status: "Failed",
          reason: error.response.status
        }
        resolve(serverResponse)
      })
    })
  }
}
