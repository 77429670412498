export interface ServerResponse {
  status: string;
  message: string;
  reason: string;
}
export class RestResponse<T> {
  constructor(success: boolean, successMessage: string, failureMessage: string, response: T, headers: any, statusCode?: number) {
    this.success = success;
    this.successMessage = successMessage;
    this.failureMessage = failureMessage;
    this.response = response;
    this.headers = headers;
    if (statusCode) {
      this.statusCode = statusCode
    }
  }
  success: boolean;
  successMessage: string;
  failureMessage: string;
  response: T;
  statusCode: number;
  headers: any;
}
export interface PaginatedResults<T> {
  results: Array<T>
  total: number
  offset: number
}
