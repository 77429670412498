import {DateTime} from "luxon"
import prettyBytes from "pretty-bytes"
import {DropzoneOptions} from "dropzone";

export class Utils {
    public static buildAuthHeader(token: string): string {
        return btoa(token);
    }

    public static formatDate(value: any): string {
        if (!value) {
            return '';
        }
      return DateTime.fromISO(
          value
        ).toLocaleString(DateTime.DATETIME_HUGE_WITH_SECONDS);
    }

    public static formatDateShort(value: any): string {
      if (!value) {
        return '';
      }
      return DateTime.fromISO(
        value
      ).toLocaleString(DateTime.DATE_SHORT);
    }

    public static formatBytes(bytes: number): string {
      return prettyBytes(bytes)
    }

    public static isMobileDevice(): boolean {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    }
}

export interface OAuthEnhancer<R, V> {
  enhance(v: V): R;
}

export class OAuthEnhancedDropzoneOptions implements OAuthEnhancer<DropzoneOptions, DropzoneOptions> {

  private readonly token: string;

  constructor(accessToken: string) {
    this.token = btoa(accessToken)
  }

  enhance(v: DropzoneOptions): DropzoneOptions {
    v["headers"] = {
      'Authorization': `Bearer ${this.token}`
    }
    return v;
  }
}
declare module 'vue/types/vue' {
  interface Vue {
    oAuthEnhancer: OAuthEnhancer<DropzoneOptions, DropzoneOptions>;
  }
}
